<template>
  <!-- 返回上一页 -->
  <div>
    <div
      class="back"
      :style="bgcol"
      :class="isborder === 'true' ? 'borderbox' : ''"
    >
      <div :class="iswhite === 'true' ? 'whitefont' : 'otherfont'">
        <i
          class="el-icon-arrow-left"
          @click="backbtn"
          :class="iswhite === 'true' ? 'whitefont' : 'otherfont'"
          v-if="backIcon"
        ></i>
        <div class="centerbox">{{ title }}</div>
      </div>
      <template v-if="isbaofei">
        <div class="righbox" @click="rightbtnParams">{{ righbox }}</div>
      </template>
      <template v-else>
        <div class="righbox" @click="rightbtn">{{ righbox }}</div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bgcol: "",
      // 单独保费
      isbaofei: false,
    };
  },
  props: {
    initback: Boolean,
    isbackperson: Boolean,
    isbackpickup:Boolean,
    isbackPath:Boolean,
    title: String,
    bgColor: String,
    righbox: String,
    righturl: String,
    isborder: String,
    iswhite: String,
    isnohref: String,
    backIcon:{
      type:Boolean,
      default:true
    },
    path:{
      type:String,
      default:'person'
    },
    customPath:Boolean,
    query:{}
  },
  mounted() {
    // 保费的话要保存数据
    if (this.righturl == "baofeiduibi") {
      this.isbaofei = true;
    }
    // if(this.righturl == 'baofeiduibi'){
    //   this.booleanBackicon = false
    // }
    this.bgcol = `background:${this.bgColor}`;
    // this.bgColor
  },
  methods: {
    rightbtnParams() {
      this.$emit("saveform");
    },
    rightbtn() {
      if (this.isnohref) {
        this.$emit("saveCityName");
      } else {
        this.$router.push({ name: this.righturl });
      }
    },
    newBackBtn(data){
        this.$router.push(data);
    },
    backbtn() {
      if (this.customPath) {

        const query = {
          name:this.path,
          query:this.query
          }
        this.newBackBtn(query)
      }else if (this.initback) {
        this.$emit("initbackf");
      } else {
        if (this.isbackperson || this.isbackpickup || this.isbackPath) {
          this.$router.push({ name: this.path });
          // this.$router.back()
        } else {
          //返回上一层
          // this.$router.go(-1);
          // this.$router.push({ path: "/person" });
          if (this.title == '地址管理') {
          // this.$router.back()
          this.$router.push({ name: "pickup" });
          }else if(this.title == '卖料' || this.title=="卖出"){
          this.$router.push({ path: "/Pricing" });
          }else if(this.title == '卖出结算'){
          this.$router.push({ name: "sellSettlement",query:{active:'Settlement'}});
          }else{
            this.$router.back()
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped="true">
.borderbox {
  border-bottom: 1px solid #e6e6e6;
}
.righbox {
  position: absolute;
  right: 20px;
  font-size: 32px;
  top: 50%;
  transform: translatey(-50%);
}
.centerbox {
  display: block;
  text-align: center;
  font-size: 36px;
}
.back {
  position: relative;
  z-index: 5;
  padding: 25px 0;
  font-size: 56px;
  color: #1a1a1a;
  i {
    color: #1a1a1a;
    position: absolute;
    left: 30px;
  }
}
.whitefont {
  color: #fff !important;
}
.otherfont {
  color: #f6d0ab !important;
  font-weight: 600;
}
</style>
